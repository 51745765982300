/**
 * @generated SignedSource<<c96999b234cad740589cd1a94e3812cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type ShopLinks_Fragment$data = {
  readonly cities: {
    readonly ' $fragmentSpreads': FragmentRefs<'ShopPrefCityLink_Fragment'>
  }
  readonly ' $fragmentSpreads': FragmentRefs<'ShopNearByCityLink_Fragment'>
  readonly ' $fragmentType': 'ShopLinks_Fragment'
}
export type ShopLinks_Fragment$key = {
  readonly ' $data'?: ShopLinks_Fragment$data
  readonly ' $fragmentSpreads': FragmentRefs<'ShopLinks_Fragment'>
}

const node: ReaderFragment = (function () {
  var v0 = {
    kind: 'Variable',
    name: 'prefectureRoma',
    variableName: 'prefectureRoma'
  }
  return {
    argumentDefinitions: [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'cityId'
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'prefectureRoma'
      }
    ],
    kind: 'Fragment',
    metadata: null,
    name: 'ShopLinks_Fragment',
    selections: [
      {
        args: [
          {
            kind: 'Variable',
            name: 'cityId',
            variableName: 'cityId'
          },
          v0 /*: any*/
        ],
        kind: 'FragmentSpread',
        name: 'ShopNearByCityLink_Fragment'
      },
      {
        alias: 'cities',
        args: [
          {
            kind: 'Literal',
            name: 'limit',
            value: 999
          },
          v0 /*: any*/
        ],
        concreteType: 'CityWithShopCountConnection',
        kind: 'LinkedField',
        name: 'listCitiesWithShopCountByPrefectureRoma',
        plural: false,
        selections: [
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'ShopPrefCityLink_Fragment'
          }
        ],
        storageKey: null
      }
    ],
    type: 'Query',
    abstractKey: null
  }
})()

;(node as any).hash = '78fafb5f5acf5456b64c1d4f3d16b5b1'

export default node
