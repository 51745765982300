import { Breadcrumb, BreadcrumbItem } from '@lifedot/atoms/Breadcrumb'
import type { FC } from 'react'
import { useFragment } from 'relay-hooks'
import { graphql } from 'relay-runtime'
import { ShopBreadcrumb_Fragment$key } from './__generated__/ShopBreadcrumb_Fragment.graphql'
import { router } from '@lifedot/router'

type ShopBreadcrumbProps = {
  shop: ShopBreadcrumb_Fragment$key
}

export const ShopBreadcrumb: FC<ShopBreadcrumbProps> = ({ shop }) => {
  const data = useFragment(fragment, shop)

  const breadcrumbItemData = [
    {
      href: '/',
      name: '【お墓・霊園・墓地探し】ライフドット'
    },
    {
      href: router.shopList({ prefectureRoma: data.prefecture.roma }),
      name: `${data.prefecture.name}の石材店・墓石販売店一覧`
    },
    {
      href: router.shopList({
        prefectureRoma: data.prefecture.roma,
        cityId: data.city.cityId
      }),
      name: `${data.city.name}の石材店・墓石販売店一覧`
    },
    {
      href: router.shop(data.shopId),
      name: data.name
    }
  ]

  return (
    <Breadcrumb>
      {breadcrumbItemData.map(({ href, name }, index) => (
        <BreadcrumbItem key={index} href={href} name={name} />
      ))}
    </Breadcrumb>
  )
}

const fragment = graphql`
  fragment ShopBreadcrumb_Fragment on Shop {
    shopId: id
    name
    city {
      cityId: id
      name
    }
    prefecture {
      roma
      name
    }
  }
`
