/**
 * @generated SignedSource<<988900b8495f908d9a0cd455b75a3a3a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type ShopBreadcrumb_Fragment$data = {
  readonly shopId: number
  readonly name: string
  readonly city: {
    readonly cityId: number
    readonly name: string
  }
  readonly prefecture: {
    readonly roma: string
    readonly name: string
  }
  readonly ' $fragmentType': 'ShopBreadcrumb_Fragment'
}
export type ShopBreadcrumb_Fragment$key = {
  readonly ' $data'?: ShopBreadcrumb_Fragment$data
  readonly ' $fragmentSpreads': FragmentRefs<'ShopBreadcrumb_Fragment'>
}

const node: ReaderFragment = (function () {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'name',
    storageKey: null
  }
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'ShopBreadcrumb_Fragment',
    selections: [
      {
        alias: 'shopId',
        args: null,
        kind: 'ScalarField',
        name: 'id',
        storageKey: null
      },
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        concreteType: 'ShopCity',
        kind: 'LinkedField',
        name: 'city',
        plural: false,
        selections: [
          {
            alias: 'cityId',
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null
          },
          v0 /*: any*/
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'ShopPrefecture',
        kind: 'LinkedField',
        name: 'prefecture',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'roma',
            storageKey: null
          },
          v0 /*: any*/
        ],
        storageKey: null
      }
    ],
    type: 'Shop',
    abstractKey: null
  }
})()

;(node as any).hash = '03e9f14dc3dd8ae159ff488148e0288a'

export default node
