import { Breadcrumb, BreadcrumbItem } from '@lifedot/atoms/Breadcrumb'
import type { FC } from 'react'
import { router } from '@lifedot/router'
import { useShopListPage } from '@/hooks/shop/use-shop-list-page'

export const ShopListBreadcrumb: FC = () => {
  const {
    prefecture,
    city,
    pagination: { currentPageNumber }
  } = useShopListPage()
  const paging = currentPageNumber > 1 ? `${currentPageNumber}ページ目` : ''

  const breadcrumbItemData = [
    {
      href: '/',
      name: '【お墓・霊園・墓地探し】ライフドット'
    },
    {
      href: router.shopList({ prefectureRoma: prefecture.roma }),
      name: `${prefecture.name}の石材店・墓石販売店一覧`
    },
    city?.name
      ? {
          href: router.shopList({
            prefectureRoma: prefecture.roma,
            cityId: city.cityId
          }),
          name: `${city.name}の石材店・墓石販売店一覧`
        }
      : null,
    paging
      ? {
          href: router.shopList({
            prefectureRoma: prefecture.roma,
            cityId: city?.cityId ?? undefined,
            page: currentPageNumber
          }),
          name: paging
        }
      : null
  ].filter((item): item is Exclude<typeof item, null> => item !== null)

  return (
    <Breadcrumb>
      {breadcrumbItemData.map(({ href, name }, index) => (
        <BreadcrumbItem key={index} href={href} name={name} />
      ))}
    </Breadcrumb>
  )
}
