import { FC } from 'react'
import { EventTracker } from '@lifedot/tracking'
import { css } from '@emotion/react'
import Image from 'next/image'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { Button } from '@lifedot/atoms/Button'
import { TelephoneNumber } from '@lifedot/components/TelephoneNumber'
import { lifedotCallCenterNumber } from '@lifedot/constants/phoneNumbers'
import PhoneIcon from '@material-ui/icons/Phone'
import { ResponsiveContents } from '@lifedot/components/ResponsiveContents'

const styles = {
  wrap: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    backgroundColor: palette.background,
    border: `1px solid ${palette.gray2}`
  }),
  content: css({
    marginLeft: 20
  }),
  telephoneNumber: css({
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
    a: css({
      // 電話番号のunderlineを打ち消すために記述
      textDecoration: 'none'
    })
  }),
  supplement: css({
    marginLeft: 8,
    fontSize: 10,
    color: palette.gray6
  }),
  box: css({
    backgroundColor: palette.white
  }),
  header: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    backgroundColor: palette.background
  }),
  copy: css({
    color: palette.main03
  }),
  description: css({
    display: 'block',
    marginTop: 4
  }),
  body: css({
    padding: '16px 20px 20px'
  }),
  note: css({
    color: palette.gray6,
    textAlign: 'center',
    fontWeight: 'bold'
  }),
  anchor: css({
    display: 'block',
    marginTop: 8,
    textDecoration: 'none'
  }),
  icon: css({
    verticalAlign: 'text-bottom'
  }),
  buttonText: css({
    display: 'flex',
    alignItems: 'center'
  }),
  free: css({
    width: 36,
    height: 36,
    padding: '6px 0',
    marginLeft: 12,
    backgroundColor: palette.white,
    borderRadius: '50%',
    color: palette.main02,
    lineHeight: 1.2
  })
}

export const ShopListCallToAction: FC = () => {
  return (
    <ResponsiveContents
      desktopContents={
        <EventTracker label="cta_電話" action="click" clone>
          <div css={styles.wrap}>
            <Image
              src="https://s3.lifedot.jp/uploads/ending/path/68726/dialogue_telephone_img.png"
              width={88}
              height={94}
              alt=""
            />
            <div css={styles.content}>
              <p>
                <span css={typography.headingXS}>
                  どのお墓がいいかわからない方へ
                </span>
                <br />
                <span css={typography.textS}>
                  お墓の選び方についてご相談を受付けています。お気軽にお電話ください。
                </span>
              </p>
              <div css={styles.telephoneNumber}>
                <TelephoneNumber large />
                <p css={styles.supplement}>
                  10:00〜19:00受付
                  <br />
                  通話無料
                </p>
              </div>
            </div>
          </div>
        </EventTracker>
      }
      mobileContents={
        <div css={styles.box}>
          <div css={styles.header}>
            <p>
              <span css={[typography.headingXS, styles.copy]}>
                どのお墓がいいかわからない方へ
              </span>
              <span css={[typography.textS, styles.description]}>
                お墓の選び方のご相談を受付けています。
                <br />
                お気軽にお電話ください。
              </span>
            </p>
            <Image
              src="https://s3.lifedot.jp/uploads/ending/path/68726/dialogue_telephone_img.png"
              width={74}
              height={80}
              alt=""
            />
          </div>
          <div css={styles.body}>
            <p css={[typography.textS, styles.note]}>
              ライフドット相談窓口（10:00〜19:00受付）
            </p>
            <EventTracker label="cta_電話" action="click" clone>
              <a href={`tel:${lifedotCallCenterNumber}`} css={styles.anchor}>
                <Button
                  mainText={
                    <p css={styles.buttonText}>
                      <span css={typography.headingS}>
                        {lifedotCallCenterNumber}
                      </span>
                      <span css={[typography.textXS, styles.free]}>
                        通話
                        <br />
                        無料
                      </span>
                    </p>
                  }
                  symbolIcon={<PhoneIcon css={styles.icon} />}
                  actionIcon={false}
                  as="div"
                />
              </a>
            </EventTracker>
          </div>
        </div>
      }
    />
  )
}
