import { typography } from '@lifedot/styles/typography'
import { FC } from 'react'
import { GridLinkItemProps, GridLinks } from '@lifedot/components/GridLinks'
import { graphql } from 'relay-runtime'
import { ShopNearByCityLink_Fragment$key } from './__generated__/ShopNearByCityLink_Fragment.graphql'
import { useFragment } from 'relay-hooks'
import { router } from '@lifedot/router'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { mq } from '@lifedot/styles/mediaQuery'

const styles = {
  wrap: css({
    padding: '12px 40px',
    backgroundColor: palette.white,
    [mq('sp')]: {
      padding: 12
    }
  })
}

type ShopNearByCityLinkProps = {
  fragmentRef: ShopNearByCityLink_Fragment$key
}

export const ShopNearByCityLink: FC<ShopNearByCityLinkProps> = ({
  fragmentRef
}) => {
  const data = useFragment(fragment, fragmentRef)
  const cities = data?.cities?.items || []
  const nearByCities = data?.nearByCities?.items[0]?.nearbyCities || []

  if (!nearByCities?.length || !cities?.length) return null

  const linkCities = cities.filter(
    (city) =>
      city.shopCount &&
      nearByCities.some((nearByCity) => nearByCity.cityId === city.cityId)
  )

  const links: GridLinkItemProps[] = linkCities.map(
    ({ name, cityId, prefectureRoma }) => ({
      url: router.shopList({
        prefectureRoma,
        cityId
      }),
      name
    })
  )
  return (
    <div css={styles.wrap}>
      <h2 css={typography.textM}>
        近隣の石材店・墓石販売店・樹木葬/納骨専門業者・寺院を探す
      </h2>
      <GridLinks items={links} />
    </div>
  )
}

const fragment = graphql`
  fragment ShopNearByCityLink_Fragment on Query
  @argumentDefinitions(
    prefectureRoma: { type: "String!" }
    cityId: { type: "Int" }
  ) {
    nearByCities: listCitiesWithShopCountByPrefectureRoma(
      prefectureRoma: $prefectureRoma
      filter: { id: { eq: $cityId } }
      limit: 999
    ) {
      items {
        nearbyCities {
          cityId: id
        }
      }
    }
    cities: listCitiesWithShopCountByPrefectureRoma(
      prefectureRoma: $prefectureRoma
      limit: 999
    ) {
      items {
        prefectureRoma
        name
        cityId: id
        shopCount
      }
    }
  }
`
