import { FC, HTMLAttributes } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { TelephoneNumber } from '@lifedot/components/TelephoneNumber'
import { mq } from '@lifedot/styles/mediaQuery'
import { EventTracker } from '@lifedot/tracking'

const styles = {
  wrap: css({
    padding: '40px 60px',
    [mq('sp')]: {
      padding: '24px 12px'
    }
  }),
  tel: css({
    padding: 8,
    border: `2px solid ${palette.gray2}`,
    backgroundColor: palette.white,
    textAlign: 'center'
  })
}

interface TelephoneProps extends HTMLAttributes<HTMLDivElement> {
  showNote?: boolean
  requestable?: boolean
  eventLabel?: string
}

export const ShopCallToAction: FC<TelephoneProps> = ({
  showNote = true,
  eventLabel = 'cta_電話'
}) => {
  return (
    <EventTracker label={eventLabel} action="click" clone>
      <div css={styles.wrap}>
        <div css={styles.tel}>
          <p>
            <b>石材店の選び方をライフドットに相談する</b>
          </p>
          <TelephoneNumber showNote={showNote} />
        </div>
      </div>
    </EventTracker>
  )
}
