import { FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'

const styles = {
  note: css({
    color: palette.gray6,
    a: {
      color: palette.gray6
    }
  })
}

export const ShopNotice: FC = () => {
  return (
    <p css={[styles.note, typography.textS]}>
      ※近隣コンテンツに誤りと思われる情報がありましたら、
      <a href="mailto:hensyubu@lifedot.jp" rel="nofollow">
        ライフドット編集部
      </a>
      へメールでご連絡ください。お送りいただいた内容を弊社内で精査した上で、情報の正確性の向上に活用させていただきます。
    </p>
  )
}
