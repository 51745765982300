import { typography } from '@lifedot/styles/typography'
import { FC } from 'react'
import { GridLinkItemProps, GridLinks } from '@lifedot/components/GridLinks'
import { PartialDisplayAccordion } from '@lifedot/components/PartialDisplayAccordion'
import { graphql } from 'relay-runtime'
import { useFragment } from 'relay-hooks'
import { ShopPrefCityLink_Fragment$key } from './__generated__/ShopPrefCityLink_Fragment.graphql'
import { router } from '@lifedot/router'

type ShopPrefCitylinkProps = {
  citiesRef: ShopPrefCityLink_Fragment$key
  prefecture: {
    name: string
    roma: string
  }
}

export const ShopPrefCityLink: FC<ShopPrefCitylinkProps> = ({
  citiesRef,
  prefecture
}) => {
  const data = useFragment(fragment, citiesRef)
  if (!data?.items?.length) return null
  const cities = data.items.filter((city) => city.shopCount)
  if (!cities.length) return null
  const links: GridLinkItemProps[] = cities.map(
    ({ prefectureRoma, cityId, name }) => ({
      url: router.shopList({
        prefectureRoma,
        cityId: cityId
      }),
      name
    })
  )
  return (
    <PartialDisplayAccordion
      accordionTitle={`${prefecture.name}の市区町村を全て表示`}
    >
      <>
        <h2 css={typography.textM}>
          {prefecture.name}
          にある石材店・墓石販売店・樹木葬/納骨専門業者・寺院を探す
        </h2>
        <GridLinks items={links} />
      </>
    </PartialDisplayAccordion>
  )
}

const fragment = graphql`
  fragment ShopPrefCityLink_Fragment on CityWithShopCountConnection {
    items {
      prefectureRoma
      name
      cityId: id
      shopCount
    }
  }
`
