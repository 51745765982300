import { shopPrefectureCityListPage_Query$data } from '@/queries/__generated__/shopPrefectureCityListPage_Query.graphql'
import { defaultSort } from '@lifedot/constants/sort'
import { createContext } from 'react'
import { SearchParams } from './dependencies'
import { shopPrefListPageIndexQuery$data } from '@/queries/__generated__/shopPrefListPageIndexQuery.graphql'

export type ShopListPageContextValue = {
  prefecture: { name: string; roma: string }
  city: shopPrefectureCityListPage_Query$data['currentCity'] | null
  searchParams: Omit<SearchParams, 'from'>
  total: number
  pagination: shopPrefListPageIndexQuery$data['shops']['pagination']
}

export const ShopListPageContext = createContext<ShopListPageContextValue>({
  prefecture: {
    name: '',
    roma: ''
  },
  pagination: {
    currentPageNumber: 1,
    hasNext: false,
    hasPrev: false,
    lastPageNumber: 1,
    perPageSize: 20,
    from: 0,
    to: 0
  },
  total: 0,
  city: null,
  searchParams: {
    cityIds: [],
    sort: defaultSort
  }
})
