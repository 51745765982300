/**
 * @generated SignedSource<<9cb82e329b3822d1ea8a90c422c650e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type ShopPrefCityLink_Fragment$data = {
  readonly items: ReadonlyArray<{
    readonly prefectureRoma: string
    readonly name: string
    readonly cityId: number
    readonly shopCount: number
  }>
  readonly ' $fragmentType': 'ShopPrefCityLink_Fragment'
}
export type ShopPrefCityLink_Fragment$key = {
  readonly ' $data'?: ShopPrefCityLink_Fragment$data
  readonly ' $fragmentSpreads': FragmentRefs<'ShopPrefCityLink_Fragment'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ShopPrefCityLink_Fragment',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'CityWithShopCount',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'prefectureRoma',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null
        },
        {
          alias: 'cityId',
          args: null,
          kind: 'ScalarField',
          name: 'id',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'shopCount',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'CityWithShopCountConnection',
  abstractKey: null
}

;(node as any).hash = 'ced3abb0eeebee992b69ae2eaff92b03'

export default node
