/**
 * @generated SignedSource<<1be76b56f2353f4546e01b51fc0be049>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type ShopNearByCityLink_Fragment$data = {
  readonly nearByCities: {
    readonly items: ReadonlyArray<{
      readonly nearbyCities: ReadonlyArray<{
        readonly cityId: number
      }>
    }>
  }
  readonly cities: {
    readonly items: ReadonlyArray<{
      readonly prefectureRoma: string
      readonly name: string
      readonly cityId: number
      readonly shopCount: number
    }>
  }
  readonly ' $fragmentType': 'ShopNearByCityLink_Fragment'
}
export type ShopNearByCityLink_Fragment$key = {
  readonly ' $data'?: ShopNearByCityLink_Fragment$data
  readonly ' $fragmentSpreads': FragmentRefs<'ShopNearByCityLink_Fragment'>
}

const node: ReaderFragment = (function () {
  var v0 = {
      kind: 'Literal',
      name: 'limit',
      value: 999
    },
    v1 = {
      kind: 'Variable',
      name: 'prefectureRoma',
      variableName: 'prefectureRoma'
    },
    v2 = {
      alias: 'cityId',
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null
    }
  return {
    argumentDefinitions: [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'cityId'
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'prefectureRoma'
      }
    ],
    kind: 'Fragment',
    metadata: null,
    name: 'ShopNearByCityLink_Fragment',
    selections: [
      {
        alias: 'nearByCities',
        args: [
          {
            fields: [
              {
                fields: [
                  {
                    kind: 'Variable',
                    name: 'eq',
                    variableName: 'cityId'
                  }
                ],
                kind: 'ObjectValue',
                name: 'id'
              }
            ],
            kind: 'ObjectValue',
            name: 'filter'
          },
          v0 /*: any*/,
          v1 /*: any*/
        ],
        concreteType: 'CityWithShopCountConnection',
        kind: 'LinkedField',
        name: 'listCitiesWithShopCountByPrefectureRoma',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'CityWithShopCount',
            kind: 'LinkedField',
            name: 'items',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'CitySimple',
                kind: 'LinkedField',
                name: 'nearbyCities',
                plural: true,
                selections: [v2 /*: any*/],
                storageKey: null
              }
            ],
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: 'cities',
        args: [v0 /*: any*/, v1 /*: any*/],
        concreteType: 'CityWithShopCountConnection',
        kind: 'LinkedField',
        name: 'listCitiesWithShopCountByPrefectureRoma',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'CityWithShopCount',
            kind: 'LinkedField',
            name: 'items',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'prefectureRoma',
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'name',
                storageKey: null
              },
              v2 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'shopCount',
                storageKey: null
              }
            ],
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: 'Query',
    abstractKey: null
  }
})()

;(node as any).hash = 'e4de6402acd8d4a93ea577aca8453acb'

export default node
