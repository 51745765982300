import { FC } from 'react'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import {
  ShopNearByCityLink,
  ShopPrefCityLink
} from '@/components/shop/ShopLink'
import { Banners } from '@lifedot/components/Banners/Banners'
import { ShopNotice } from '@/components/shop/ShopNotice'
import { graphql, useFragment } from 'relay-hooks'
import { ShopLinks_Fragment$key } from './__generated__/ShopLinks_Fragment.graphql'

const styles = {
  links: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    marginBottom: 60
  }),
  wrap: css({
    [mq('sp')]: {
      paddingInline: 8
    }
  })
}

type ShopLinksProps = {
  fragmentRef: ShopLinks_Fragment$key
  prefecture: {
    name: string
    roma: string
  }
}

export const ShopLinks: FC<ShopLinksProps> = ({ fragmentRef, prefecture }) => {
  const data = useFragment(fragment, fragmentRef)
  return (
    <div css={styles.links}>
      <ShopPrefCityLink citiesRef={data?.cities} prefecture={prefecture} />
      <ShopNearByCityLink fragmentRef={data} />
      <div css={styles.wrap}>
        <ShopNotice />
      </div>
      <div css={styles.wrap}>
        <Banners shows={['client']} />
      </div>
    </div>
  )
}

const fragment = graphql`
  fragment ShopLinks_Fragment on Query
  @argumentDefinitions(
    prefectureRoma: { type: "String!" }
    cityId: { type: "Int" }
  ) {
    ...ShopNearByCityLink_Fragment
      @arguments(prefectureRoma: $prefectureRoma, cityId: $cityId)
    cities: listCitiesWithShopCountByPrefectureRoma(
      prefectureRoma: $prefectureRoma
      limit: 999
    ) {
      ...ShopPrefCityLink_Fragment
    }
  }
`
